
<template>
  <div class='account_manage_wrap'>
     <div class="padding_box custom_bg_white hover_animat">
          <div class="data_table_box">
                <!-- <h2 class="data_table_title">账号列表</h2> -->
                <div class="data_table_top">
                    <div class="data_table_top_left">
                        <div>
                            <Button  icon="md-add" type="success" @click="createData.show=true;">新增</Button>
                            <Button  icon="md-trash" type="error" @click="deleteFn">删除</Button>
                        </div>
                    </div>
                    <div class="data_table_top_right">
                       
                    </div>
                </div>
                <div class="table_scroll_wrap">
                    <Table style="min-width:1200px" :loading="tableLoading" stripe :columns="tableColumn" :data="tableData"   @on-selection-change="checkAllGroupChange">
                        <template slot-scope="{row}" slot="operation">
                            <Button style="margin-right:5px;"  type="error"  @click="showPasswordModifyFn(row)" :disabled="person!==row.account">改密</Button>
                            <Button  type="warning"  @click="showModifyFn(row)">修改</Button>
                        </template>
                        <template slot-scope="{row,index}" slot="active">
                            <i-switch :disabled="person===row.account"  v-model="row.active" :before-change="beforeSwitchFn.bind(this,row)" />
                        </template>
                       
                        <template slot-scope="{row,index}" slot="role">
                            <Tag v-if="row.role==='1'" color="primary">{{getRoleTextFn(row.role)}}</Tag>
                            <Tag v-if="row.role==='2'" color="success">{{getRoleTextFn(row.role)}}</Tag>
                        </template>
                    </Table>
                </div>
                <div class="pagination_wrap">
                    <div class="pagination_wrap">
                        <Page transfer show-elevator  show-total  :total="totalCount" @on-change="changePageFn"  :current="currentPage" />
                    </div>
                </div>
          </div>
      </div>

        <Modal  title="新增账号" v-model="createData.show" :mask-closable="false" width="720" @on-cancel="cancelCreateFn">
            <div class="modal_scroll_wrap">
                <div class="edit_wrap">
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>账户名</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input v-model="createData.data.account" style="width: 100%" @on-blur="handleBlurFn('createData','account')"/>
                        <span :class="createData.warning.account ? 'warning' : ''">{{createData.text.account}}</span>
                    </div>
                </div>
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>姓名</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input v-model="createData.data.name"  style="width: 100%" @on-blur="handleBlurFn('createData','name')"/>
                        <span :class="createData.warning.name ? 'warning' : ''">{{createData.text.name}}</span>
                    </div>
                </div>
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>手机号</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input v-model="createData.data.phone"  style="width: 100%" @on-blur="handleBlurFn('createData','phone')"/>
                        <span :class="createData.warning.phone ? 'warning' : ''">{{createData.text.phone}}</span>
                    </div>
                </div>
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>密码</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input type="password" v-model="createData.data.password"  style="width: 100%" @on-blur="handleBlurFn('createData','password')"/>
                        <span :class="createData.warning.password ? 'warning' : ''">{{createData.text.password}}</span>
                    </div>
                </div>
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>确认密码</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input type="password" v-model="createData.data.certainPasswordCreate"  style="width: 100%" @on-blur="handleBlurFn('createData','certainPasswordCreate')"/>
                        <span :class="createData.warning.certainPasswordCreate ? 'warning' : ''">{{createData.text.certainPasswordCreate}}</span>
                    </div>
                </div>
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>账号类型</h4>
                    </div>
                    <div class="edit_item_right">
                        <Select transfer clearable style="width:200px" v-model="createData.data.role" placeholder="请选择类型">
                        <Option v-for="item in roleTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                        <span :class="createData.warning.role ? 'warning' : ''">{{createData.text.role}}</span>
                    </div>
                </div>
                </div>
                
            </div>
            <div slot="footer">
                <Button type="primary"  @click="createFn">确定新增</Button>
            </div>
        </Modal>
        

        <Drawer  width="720" title="修改密码" :closable="true" :mask-closable="false" v-model="passwordModifyData.show" @on-close="cancelPasswordModifyFn">
            <div class="drawer_scroll_wrap">
                <div class="edit_wrap">
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>账户名</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="passwordModifyData.data.account" disabled style="width: 100%" @on-blur="handleBlurFn('passwordModifyData','account')"/>
                            <span :class="passwordModifyData.warning.account ? 'warning' : ''">{{passwordModifyData.text.account}}</span>
                        </div>
                    </div>
                   <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>新密码</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input type="password" v-model="passwordModifyData.data.newPassword"  style="width: 100%" @on-blur="handleBlurFn('passwordModifyData','newPassword')"/>
                        <span :class="passwordModifyData.warning.newPassword ? 'warning' : ''">{{passwordModifyData.text.newPassword}}</span>
                    </div>
                </div>
                <div class="edit_item">
                    <div class="edit_item_left">
                        <h4>确认密码</h4>
                    </div>
                    <div class="edit_item_right">
                        <Input type="password" v-model="passwordModifyData.data.certainPasswordModify"  style="width: 100%" @on-blur="handleBlurFn('passwordModifyData','certainPasswordModify')"/>
                        <span :class="passwordModifyData.warning.certainPasswordModify ? 'warning' : ''">{{passwordModifyData.text.certainPasswordModify}}</span>
                    </div>
                </div>
                </div>
            </div>

            <div class="drawer_submit_wrap">
                <Button type="primary"  @click="passwordModifyFn">立即改密</Button>
            </div>
        </Drawer>
        <Drawer  width="720" title="修改账号" :closable="true" :mask-closable="false" v-model="modifyData.show" @on-close="cancelModifyFn">
            <div class="drawer_scroll_wrap">
                <div class="edit_wrap">
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>账户名</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.account" disabled style="width: 100%" @on-blur="handleBlurFn('modifyData','account')"/>
                            <span :class="modifyData.warning.account ? 'warning' : ''">{{modifyData.text.account}}</span>
                        </div>
                    </div>
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>姓名</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.name"  style="width: 100%" @on-blur="handleBlurFn('modifyData','name')"/>
                            <span :class="modifyData.warning.name ? 'warning' : ''">{{modifyData.text.name}}</span>
                        </div>
                    </div>
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>手机号</h4>
                        </div>
                        <div class="edit_item_right">
                            <Input v-model="modifyData.data.phone"  style="width: 100%" @on-blur="handleBlurFn('modifyData','phone')"/>
                            <span :class="modifyData.warning.phone ? 'warning' : ''">{{modifyData.text.phone}}</span>
                        </div>
                    </div>
                   
                    <div class="edit_item">
                        <div class="edit_item_left">
                            <h4>账号类型</h4>
                        </div>
                        <div class="edit_item_right">
                            <Select transfer clearable style="width:200px" v-model="modifyData.data.role" placeholder="请选择类型">
                                <Option v-for="item in roleTypeList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                            <span :class="modifyData.warning.role ? 'warning' : ''">{{modifyData.text.role}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="drawer_submit_wrap">
                <Button type="primary"  @click="modifyFn">立即修改</Button>
            </div>
        </Drawer>

        <Modal v-model="statusModal.show" title="" :footer-hide="true" :mask-closable="false" :closable="false" width="360">
            <div style="text-align:center;padding:20px 0">
                <Spin fix v-if="!statusModal.showSuccess&&!statusModal.showError">
                    <Icon type="ios-loading" size=18 class="spin-icon-rotate"></Icon>
                    <div>{{statusModal.text}}</div>
                </Spin>
                <Spin fix v-if="statusModal.showSuccess" style="color:#00ad19">
                    <Icon type="ios-checkmark-circle" size=18 />
                    <div>{{statusModal.successText}}</div>
                </Spin>
                <Spin fix v-if="statusModal.showError" style="color:#f72b2b">
                    <Icon type="ios-close-circle" size=18 />
                    <div>{{statusModal.errorText}}</div>
                </Spin>
            </div>
        </Modal>

  </div>
</template>

<script>
import Vue from "vue"
import { Table ,Tag ,Icon,Page,Input,Button,Select,Option,Switch,Drawer,Spin} from 'view-design';
Vue.component('Table', Table);
Vue.component('Tag', Tag);
Vue.component('Icon', Icon);
Vue.component('Page', Page);
Vue.component('Input', Input);
Vue.component('Button', Button);
Vue.component('i-switch',Switch);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Drawer', Drawer);
Vue.component('Spin', Spin);
import NET_PORT from "../../api/port.js"
import { exitFn } from "../../api/logout"
import roleTypeList from "../../assets/data/role_type.js"
export default {
  name:'account_manage',
  components:{},
  props:{},
  data(){
    return {
        person:this.$store.state.user,
        role:this.$store.state.role,
        firstEnter:true,
        statusModal:{
            show:false,
            showSuccess:false,
            showError:false,
            text:'正在上传',
            successText:'上传成功！',
            errorText:'上传失败！',
        },
      totalCount: 0,
      pageSize: 10,
      currentPage: 1,
      selection:[],
      tableLoading:false,

      roleTypeList:[],


      passwordModifyData:{
            show:false,
            data:{
                account:'',
                newPassword:'',
                certainPasswordModify:'',
            },
            verify:{
                account:false,
                newPassword:false,
                certainPasswordModify:false,
            },
            text:{
                account:'',
                newPassword:'以字母开头，长度6-16位，只能包含字母、数字和下划线',
                certainPasswordModify:'',
            
            },
            warning:{
                account:false,
                newPassword:false,
                certainPasswordModify:false,
            },
      },
     
      createData:{
            show:false,
            data:{
              account:'',
              password:'',
              certainPasswordCreate:'',
              name:'',
              phone:'',
              role:'',
              
            },
            verify:{
              account:false,
              password:false,
              certainPasswordCreate:false,
              name:false,
              phone:false,
              role:false,
              
            },
            text:{
              account:'以字母开头，长度5-12位，允许字母数字下划线',
              password:'以字母开头，长度6-16位，只能包含字母、数字和下划线',
              certainPasswordCreate:'',
              name:'',
              phone:'',
              role:'',
              
            },
            warning:{
              account:false,
              password:false,
              certainPasswordCreate:false,
              name:false,
              phone:false,
              role:false,
              
            },
        },

        modifyData:{
            show:false,
            data:{
              account:'',
              name:'',
              phone:'',
              role:'',
            },
            verify:{
              account:false,
              name:false,
              phone:false,
              role:false,
            },
            text:{
              account:'',
              name:'',
              phone:'',
              role:'',
            },
            warning:{
              account:false,
              name:false,
              phone:false,
              role:false,
              
            },
        },

        
      tableColumn:[
            {
                type: 'selection',
                width: 60,
                align: 'center'
            },
            {
                title: '序号',
                key: 'no',
                width:80,
            },
           
            {
                title: '账户名',
                key: 'account',
            },
           
            {
                title: '姓名',
                key: 'name',
            },

            {
                title: '手机号',
                key: 'phone',
            },
           
            {
                title: '账号类型',
                key: 'role',
                slot: 'role',
            },
            
            {
                title: '启用',
                key: 'active',
                slot:'active',
                width:100,
            },
           
           
            {
                title: '操作',
                key: 'operation',
                slot: 'operation',
                width:250,
                align:'center',
            },
      ],
      tableData:[],
      
    }
  },
  watch:{},
  computed:{},
  methods:{

    deleteFn(){
        if(!this.selection.length){
            this.$Message.warning({
                title:'提示！',
                content:'请至少选择一项！'
            })
        }else{
             this.$Modal.confirm({
                title: '删除确认！',
                content: '<h3 style="color:#ed4014">您确定要删除这'+this.selection.length+'条数据吗？</h3>',
                onOk: () => {
                    // 显示出正在上传
                    this.statusModal.show=true;
                    this.statusModal.text='正在删除';
                    this.statusModal.successText='删除成功！';
                    this.statusModal.errorText='删除失败！';
                   this.certainDeleteFn();
                }
            });
        }
    },

    certainDeleteFn(){
        let bodyData=[]
        this.selection.forEach((item,index)=>{
            bodyData.push({
                username:item.account,
            })
        })

        this.$axios.post(NET_PORT.accountDelete,bodyData)
        .then((res)=>{
            
            if(res.data.code===200){
                this.successFn();
                this.tableData=[];
                this.totalCount=0
                this.currentPage=1
                this.getDataFn();
            }else{
                this.$Message.error({
                        content:res.data.msg,
                        duration:2,
                })
                this.errorFn();
            }
        })
        .catch(()=>{
            this.errorFn();
            this.$Message.error({
                    content:"网络错误！",
                    duration:2,
            })
        })
      
    },
     
    checkAllGroupChange(selection){
        this.selection=selection;
    },
    changePageFn(page){
        this.currentPage=page;
        this.getDataFn();
    },
    changePageSizeFn(size){
      this.currentPage=1;
      this.pageSize=size;
      this.getDataFn();
    },

    

    showPasswordModifyFn(obj){
        this.passwordModifyData.data.account=obj.account;
        this.passwordModifyData.show=true;
    },

    passwordModifyFn(){
        this.handleBlurFn('passwordModifyData','account')
        this.handleBlurFn('passwordModifyData','newPassword')
        this.handleBlurFn('passwordModifyData','certainPasswordModify')
        var verifyErrorNum=0;
        for(var k in this.passwordModifyData.verify){
            if(!this.passwordModifyData.verify[k]){
                verifyErrorNum++;
            }
        }

        if(!verifyErrorNum){
            this.statusModal.show=true;
            this.statusModal.text='正在改密';
            this.statusModal.successText='改密成功！';
            this.statusModal.errorText='改密失败！';
          
            let bodyData={
                username:this.passwordModifyData.data.account,
                newpassword:this.passwordModifyData.data.newPassword,
            }

            this.$axios.post(NET_PORT.accountModify,bodyData)
            .then((res)=>{
                if(res.data.code===200){
                   this.passwordModifyData.show=false;
                    this.cancelPasswordModifyFn();
                    this.successFn();
                    this.$Modal.warning({
                        title: '提示！',
                        content: '您已修改密码，请重新登录！',
                        okText: '重新登录',
                        onOk: () => {
                            exitFn();
                        }
                    })
                }else{
                    this.$Message.error({
                            content:res.data.msg,
                            duration:2,
                    })
                    this.errorFn();
                }
            })
            .catch(()=>{
                this.errorFn();
                this.$Message.error({
                        content:"网络错误！",
                        duration:2,
                })
            })

        }
        
    },

    showModifyFn(obj){
        this.modifyData.data.account=obj.account;
        this.modifyData.data.name=obj.name;
        this.modifyData.data.phone=obj.phone;
        this.modifyData.data.role=obj.role;
        this.modifyData.show=true;
    },

    modifyFn(){
        this.handleBlurFn('modifyData','account')
        this.handleBlurFn('modifyData','name')
        this.handleBlurFn('modifyData','phone')
        this.handleBlurFn('modifyData','role')
        var verifyErrorNum=0;
        for(var k in this.modifyData.verify){
            if(!this.modifyData.verify[k]){
                verifyErrorNum++;
            }
        }

        if(!verifyErrorNum){
            this.statusModal.show=true;
            this.statusModal.text='正在修改';
            this.statusModal.successText='修改成功！';
            this.statusModal.errorText='修改失败！';
            let role='';
            this.roleTypeList.forEach((item,index)=>{
                if(item.value===this.modifyData.data.role){
                    role=item.label;
                }
            })

            let bodyData={
                username:this.modifyData.data.account,
                personName:this.modifyData.data.name,
                phone:this.modifyData.data.phone,
                rolelevel:this.modifyData.data.role,
                role:role,
            }

            this.$axios.post(NET_PORT.accountModify,bodyData)
            .then((res)=>{
                if(res.data.code===200){
                   this.modifyData.show=false;
                    this.cancelModifyFn();
                    this.successFn();
                    this.tableData=[];
                    this.totalCount=0
                    this.currentPage=1
                    this.getDataFn();
                }else{
                    this.$Message.error({
                            content:res.data.msg,
                            duration:2,
                    })
                    this.errorFn();
                }
            })
            .catch(()=>{
                this.errorFn();
                this.$Message.error({
                        content:"网络错误！",
                        duration:2,
                })
            })

        }
    },

    

    cancelModifyFn(){
        this.modifyData={
            show:false,
            data:{
              account:'',
              name:'',
              phone:'',
              role:'',
            },
            verify:{
              account:false,
              name:false,
              phone:false,
              role:false,
            },
            text:{
              account:'以字母开头，长度5-12位，允许字母数字下划线',
              name:'',
              phone:'',
              role:'',
            },
            warning:{
              account:false,
              name:false,
              phone:false,
              role:false,
              
            },
        }
    },

    cancelPasswordModifyFn(){
        this.passwordModifyData={
                show:false,
                data:{
                    account:'',
                    newPassword:'',
                    certainPassword:'',
                },
                verify:{
                    account:false,
                    newPassword:false,
                },
                text:{
                    account:'以字母开头，长度5-12位，允许字母数字下划线',
                    newPassword:'以字母开头，长度6-16位，只能包含字母、数字和下划线',
                
                },
                warning:{
                    account:false,
                    newPassword:false,
                },
        };
    },

  
   
    cancelCreateFn(){
        this.createData={
            show:false,
            data:{
              account:'',
              password:'',
              certainPasswordCreate:'',
              name:'',
              phone:'',
              role:'',
              
            },
            verify:{
              account:false,
              password:false,
              certainPasswordCreate:false,
              name:false,
              phone:false,
              role:false,
              
            },
            text:{
              account:'以字母开头，长度5-12位，允许字母数字下划线',
              password:'以字母开头，长度6-16位，只能包含字母、数字和下划线',
              certainPasswordCreate:'',
              name:'',
              phone:'',
              role:'',
              
            },
            warning:{
              account:false,
              password:false,
              certainPasswordCreate:false,
              name:false,
              phone:false,
              role:false,
              
            },
        };
    },
    createFn(){
        this.handleBlurFn('createData','account')
        this.handleBlurFn('createData','password')
        this.handleBlurFn('createData','certainPasswordCreate')
        this.handleBlurFn('createData','name')
        this.handleBlurFn('createData','phone')
        this.handleBlurFn('createData','role')

        var verifyErrorNum=0;
        for(var k in this.createData.verify){
            if(!this.createData.verify[k]){
                verifyErrorNum++;
            }
        }

        if(!verifyErrorNum){
            //显示出正在新增
            this.statusModal.show=true;
            this.statusModal.text='正在新增';
            this.statusModal.successText='新增成功！';
            this.statusModal.errorText='新增失败！';
            let role='';
            this.roleTypeList.forEach((item,index)=>{
                if(item.value===this.createData.data.role){
                    role=item.label;
                }
            })

            let bodyData={
                username:this.createData.data.account,
                password:this.createData.data.password,
                personName:this.createData.data.name,
                phone:this.createData.data.phone,
                rolelevel:this.createData.data.role,
                role:role,
                department:'默认部门',
                belongunit:'默认单位',
                rolepath:this.createData.data.role=='1' ? '/user/query,/project/query,/apply/query,/excel/download' : '/user/query,/user/add,/user/del,/user/update,/project/query,/project/add,/project/del,/project/update,/apply/query,/excel/download',
                isUse:1,
            }

            this.$axios.post(NET_PORT.accountAdd,bodyData)
            .then((res)=>{
                if(res.data.code===200){
                   this.createData.show=false;
                    this.cancelCreateFn();
                    this.successFn();
                    this.tableData=[];
                    this.totalCount=0
                    this.currentPage=1
                    this.getDataFn();
                }else{
                    this.$Message.error({
                            content:res.data.msg,
                            duration:2,
                    })
                    this.errorFn();
                }
            })
            .catch(()=>{
                this.errorFn();
                this.$Message.error({
                        content:"网络错误！",
                        duration:2,
                })
            })


        }

       
    },
    
    
    beforeSwitchFn(row){
        return new Promise((resolve) => {
            let bodyData={
                username:row.account,
                isUse:row.active ? 0 : 1,
            }

            this.$Modal.confirm({
                title: '状态切换确认！',
                content: bodyData.isUse===1 ?'您确定要启用账号吗？' : '您确定要禁用账号吗？',
                onOk: () => {

                    this.$axios.post(NET_PORT.accountModify,bodyData)
                    .then((res)=>{
                        if(res.data.code===200){
                            resolve();
                            this.$Message.success('状态切换成功！')
                    
                        }else{
                            this.$Message.error('状态切换失败！')
                        }
                    })
                    .catch(()=>{
                        this.$Message.error('状态切换失败！')
                    })

                }
            });
        });
    },
    

    paramsStrFn(params){
        let paramsStr = '';
        for(let key in params){
            paramsStr += key + '=' + params[key] + '&'
        }
        paramsStr = paramsStr.slice(0,-1);
        return paramsStr
    },
   

    successFn(){
        this.statusModal.showSuccess=true;
        setTimeout(()=>{
            this.statusModal.show=false;
            setTimeout(()=>{
                this.statusModal.showSuccess=false;
                this.statusModal.showError=false;
            },500)
        },1000)
    },

    errorFn(){
        this.statusModal.showError=true;
        setTimeout(()=>{
            this.statusModal.show=false;
            setTimeout(()=>{
                this.statusModal.showSuccess=false;
                this.statusModal.showError=false;
            },500)
        },1000)
    },

    handleBlurFn(type,key){
        let obj=type;
        let name=key;
        let text="";
        let warning=false;
        if(key==='account'){
            if(this[obj].data.account.trim()===''){
            
                text='账户名不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                let accountReg=/^[a-zA-Z][a-zA-Z0-9_]{4,11}$/;
                if(accountReg.test(this[obj].data.account.trim())){
                    
                    text=''
                    warning=false;
                    this[obj].verify[name]=true
                }else{
                
                    text='请输入正确格式的账户名（以字母开头，长度5-12位，允许字母数字下划线）'
                    warning=true;
                    this[obj].verify[name]=false
                }
            }

        }else if(key==="name"){
                    
                    if(this[obj].data.name.trim()===''){
                        text='姓名不能为空！'
                        warning=true;
                        this[obj].verify[name]=false
                    
                    }else {
                        var nameReg=/^[\u4E00-\u9FA5]{2,4}$/;
                        if(nameReg.test(this[obj].data.name.trim())){
                        
                            text=''
                            warning=false;
                            this[obj].verify[name]=true
                        }else{
                        
                            text='请输入正确的中文姓名！'
                            warning=true;
                            this[obj].verify[name]=false
                        }
                    }
        }else if(key==="phone"){
                    
                    if(this[obj].data.phone.trim()===''){
                        text='手机号不能为空！'
                        warning=true;
                        this[obj].verify[name]=false
                    
                    }else {
                        
                        var phoneReg=/^1[3456789]\d{9}$/;
                        if(phoneReg.test(this[obj].data.phone.trim())){
                        
                            text=''
                            warning=false;
                            this[obj].verify[name]=true
                        }else{
                        
                            text='请输入正确格式的手机号！'
                            warning=true;
                            this[obj].verify[name]=false
                        }
                    }
        }else if(key==="password"){
            if(this[obj].data.password.trim()===''){
            
                text='密码不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                let passwordReg=/^[a-zA-Z][a-zA-Z0-9_]{5,15}$/;
                if(passwordReg.test(this[obj].data.password.trim())){
                
                    text=''
                    warning=false;
                    this[obj].verify[name]=true
                }else{
                
                    text='请输入正确格式的密码（以字母开头，长度6-16位，只能包含字母、数字和下划线）'
                    warning=true;
                    this[obj].verify[name]=false
                }
            }
        }else if(key==="newPassword"){
            if(this[obj].data.newPassword.trim()===''){
            
                text='新密码不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                let newPasswordReg=/^[a-zA-Z][a-zA-Z0-9_]{5,15}$/;
                if(newPasswordReg.test(this[obj].data.newPassword.trim())){
                
                    text=''
                    warning=false;
                    this[obj].verify[name]=true
                }else{
                
                    text='请输入正确格式的密码（以字母开头，长度6-16位，只能包含字母、数字和下划线）'
                    warning=true;
                    this[obj].verify[name]=false
                }
            }
        }else if(key==="certainPasswordCreate"){
            if(this[obj].data.certainPasswordCreate.trim()===''){
            
                text='确认密码不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                if(this[obj].data.certainPasswordCreate.trim()!==this[obj].data.password.trim()){
            
                    text='两次输入密码不一致！'
                    warning=true;
                    this[obj].verify[name]=false
                }else{
                    text=''
                    warning=false;
                    this[obj].verify[name]=true
                }
            }
            
        }else if(key==="certainPasswordModify"){
            if(this[obj].data.certainPasswordModify.trim()===''){
            
                text='确认密码不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                if(this[obj].data.certainPasswordModify.trim()!==this[obj].data.newPassword.trim()){
            
                    text='两次输入密码不一致！'
                    warning=true;
                    this[obj].verify[name]=false
                }else{
                    text=''
                    warning=false;
                    this[obj].verify[name]=true
                }
            }
            
        }else if(key==="role"){
            if(this[obj].data.role.trim()===''){
            
                text='账号类型不能为空！'
                warning=true;
                this[obj].verify[name]=false
            }else{
                text=''
                warning=false;
                this[obj].verify[name]=true
            }
            
        }

        this[obj].text[name]=text;
        if(warning){
            this[obj].warning[name]=true
        }else{
            this[obj].warning[name]=false
        }
    },

   


    getDataFn(){
        // this.tableData=[
        //     {
        //         no:1,
        //         account:'admin1',
        //         name:'李文',
        //         phone:'13550684369',
        //         role:'1',
        //         active:true,
        //     }
        // ]

        this.tableLoading=true;
        this.selection=[];
      
        let bodyData={
            username:'',
            personName:'',
            role:'',
            belongunit:'',
            department:'',
            isUse:null,
        }
        let urlData={
            pageIndex:this.currentPage,
            pageSize:this.pageSize,
        }

        let paramsStr='?'+this.paramsStrFn(urlData)
        this.$axios.post(NET_PORT.accountList+paramsStr,bodyData)
        .then((res)=>{
            if(res.data.code===200){
                this.tableData=[];
                for(let i=0;i<res.data.data.length-1;i++){
                    this.tableData.push({
                        no:i+1,
                        account:res.data.data[i].username,
                        name:res.data.data[i].personName,
                        phone:res.data.data[i].phone,
                        role:res.data.data[i].rolelevel,
                        rolepath:res.data.data[i].rolepath,
                        department:res.data.data[i].department,
                        active:res.data.data[i].isUse===1 ? true : false,
                    })
                }
                this.totalCount=Number(res.data.data[res.data.data.length-1].total);
                this.tableLoading=false;
                if(this.firstEnter){
                    this.firstEnter=false;
                    this.$Loading.finish();
                }
            }else{
                this.$Message.error({
                        content:res.data.msg,
                        duration:2,
                })
            }
        })
        .catch(()=>{
            this.$Message.error({
                    content:"网络错误！",
                    duration:2,
            })
        })


     
        
    },

  

    getRoleTextFn(role){
        let text='';
        roleTypeList.forEach((item,index)=>{
            if(item.value===role){
                text=item.label;
            }
        })
        return text
    },
  },
  created(){
    this.roleTypeList=roleTypeList;
    this.$Loading.start();
    this.getDataFn();
  },
  mounted(){}
}
</script>
<style lang="scss" scoped>
    @import "../../assets/scss/common/apply.scss";
</style>